<template>
  <div
    class="inputo p-1 text-c14"
    :class="{'error': error !== null,
             'success': success === true,
             'deseable': deseable === true}"
    :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
  >
    <div
      v-if="!deseable"
      class="flex h-full items-center"
    >
      <icon
          :data="icon"
          height="25"
          width="25"
          class="icone cursor-pointer ml-2"
          v-if="icon !== null"
          original
      />
      <input
        v-if="type === null"
        v-model="value"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{'font-size': size}"
        :maxlength="maxLength"
        @keyup.enter="onEnter"
      >
      <input
        v-if="type !== null && type !== 'email'"
        v-model="value"
        type="email"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{'font-size': size}"
        @keypress="isNumberOnly"
        :maxlength="maxLength"
      >

      <input
        v-if="type === 'email'"
        v-model="value"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{'font-size': size}"
      >
      <div class="md:w-1/6 w-2/5 flex justify-end">
        <div
          v-if="label !== null"
          class="lab mr-4 p-2 flex items-center justify-center w-auto"
        >
          <div>{{ label }}</div>
        </div>
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
  },

  props: {
    searchMode: {
      type: Boolean,
      default: false
    },
    deseable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    valueT: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    },
    maxLength: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      error: null,
      success: false,
      value: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (!this.searchMode) {
        if (this.type === 'email') {
          if (this.emailValidation(this.value) === true) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.success = true
            this.$emit('info', this.value)
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.error = 'email'
          }
        } else {
          this.$emit('info', this.value)
        }
      }
    }
  },

  created() {
    this.value = this.valueT
  },

  methods: {
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },

    emailValidation (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    },

    onEnter () {
      if (this.searchMode) {
        if (this.type === 'email') {
          if (this.emailValidation(this.value) === true) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.success = true
            this.$emit('info', this.value)
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.error = 'email'
          }
        } else {
          this.$emit('info', this.value)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
}
input{
  color: #0e0e0e;
  background-color: transparent;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
.error{
  border: 1px solid #ea9f9f!important;
}
.success{
  border: 1px solid #5fc187!important;
}
.lab{
  background: #F2F2F2;
  border-radius: 10px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.deseable{
  background: #eeeeee;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .inputo{
    height: 65px;
  }
  input{
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .inputo {
    height: 55px;
  }
  input{
    font-size: 14px;
  }
}
</style>
