<template>
  <div class="p-6 pr-6 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
            <icon
                :data="icons.back"
                height="40"
                width="40"
                class="mr-4 cursor-pointer"
                original
                @click="goBack"
            />

            <icon
                :data="icons.entreprise"
                height="20"
                width="20"
                class="mr-3 cursor-pointer"
                original
            />

            <div class="text-left text-c22 items-center font-c8">
              <span v-if="company">{{ company.fullName }}</span>
            </div>
        </div>

        <div class="w-1/2 flex justify-end">
          <div class="w-1/2">
            <inputo :icon="icons.search" @info="retourSearch" height="50px" placeholder="Rechercher..." />
          </div>

        </div>
      </div>

      <div class="mt-6">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import entreprise from '../../assets/icons/ic-entreprise.svg'
import back from '../../assets/icons/backBut.svg'
import globalView from '../../component/cdevs/collaborateurs/globalData'
import inputo from '../../component/helper/form/input'

export default {
  name: "Index",

  components: {
    globalView,
    inputo
  },

  data () {
    return {
      icons: {
        search,
        entreprise,
        back
      },
      recherche: null,
      company: null
    }
  },

  created () {
    this.company = this.$store.getters.trackCompany
    // console.log(this.company)
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    goBack () {
      window.history.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
